import { Box, Button, Center, Circle, HStack, IconButton, Image, Img, Menu, MenuButton, MenuDivider, MenuItem, MenuList, StackProps, Switch, Text, TextProps, VStack } from "@chakra-ui/react"
import { Storage } from '@ionic/storage'
import { useCallback, useContext, useEffect, useState } from "react"
import { FiBell, FiChevronDown, FiShoppingCart } from "react-icons/fi"
import { RiUserLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { SelectUploadType } from "../modules/contents/components/SelectUploadType"
import { useScreenSize } from "../providers/ScreenSizeProvider"
import { UserContext } from "../providers/UserProvider"
import { MobileHeader } from "./MobileHeader"
import { PubSub } from "@inficen/react-events"
import { GetCartContents } from "../services/UserService"
import { GetListing } from "../services/NotificationService"
import moment from "moment"
import { findWhere } from "underscore"
import { BsFillBellFill } from "react-icons/bs"

type Events = {
    name: "add-cart"
    payload: any
}

const { publish, subscribe } = new PubSub<Events>()

export const addToCart = (content: any) => {
    publish('add-cart', content)
}

interface HeaderProps extends StackProps {
    activeNav: string;
}

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

const container_style: StackProps = {
    w: 'full',
    py: '24px',
    px: '80px',
    justify: 'space-between',
    pos: 'relative',
    zIndex: 1000,
    transition: 'background-color 200ms linear'
}

const floating_container_style: StackProps = {
    ...container_style,
    pos: 'fixed',
    zIndex: 20,
    bg: 'transparent',
    transition: 'background-color 200ms linear'
}

const nav_style: TextProps = {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 'normal',
    cursor: 'pointer'
}

const active_nav_style: TextProps = {
    ...nav_style,
    color: 'green.400'
}

export const Header = ({ activeNav: active_nav, ...rest }: HeaderProps) => {
    const { user } = useContext(UserContext)
    const [added_cart_content, setAddedCartContent] = useState<any>()
    const [cart_contents, setCartContents] = useState<any[]>([])
    const [notifications, setNotifications] = useState<any[]>([])
    const [has_unread_notification, setHasUnreadNotification] = useState<boolean>(false)

    const navigate = useNavigate()
    const { isMobile } = useScreenSize()

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        (e: any) => {
            const window = e.currentTarget
            setY(window.scrollY)
        }, [y]
    );

    useEffect(()=> {
        if(user){
            (async ()=> {
                const notifications = await GetListing()
                const has_unread_notification = findWhere(notifications, { is_read: false })
                if(has_unread_notification){
                    setHasUnreadNotification(()=> true)
                }
                setNotifications(()=> [...notifications])
            })();
        }
    }, [user])

    useEffect(() => {
        (async () => {
            const result = await GetCartContents()
            setCartContents(() => [...result])
        })()
    }, [added_cart_content])

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    subscribe('add-cart', content => setAddedCartContent(content))

    const logout = async () => {
        const storage = await store.create();
        await storage.clear()
        window.location.href = '/'
    }

    return (
        <>
            {!isMobile &&
                <HStack {...floating_container_style} bg={y > 0 ? '#262333' : 'transparent'} {...rest}  >
                    <HStack align={'center'} gap={'24px'}>
                        <Img cursor={'pointer'} onClick={() => navigate('/')} h={'20px'} src="/assets/images/logo.png" />
                        <Text onClick={() => navigate('/')} {...active_nav === 'HOME' && active_nav_style || nav_style}>
                            Home
                        </Text>
                        <Text onClick={() => navigate('/explore')} {...active_nav === 'EXPLORE' && active_nav_style || nav_style}>
                            Explore
                        </Text>
                        <Text onClick={() => navigate('/genre')} {...active_nav === 'GENRE' ? active_nav_style : nav_style}>
                            Genre
                        </Text>
                        <Text {...active_nav === 'MERCHANDISE' ? active_nav_style : nav_style}>
                            Merchandise
                        </Text>
                        <Text onClick={() => navigate('/about-us')} {...active_nav === 'ABOUT_US' ? active_nav_style : nav_style}>
                            About Us
                        </Text>
                        <Text {...active_nav === 'HELP_CENTER' ? active_nav_style : nav_style}>
                            Help Center
                        </Text>
                    </HStack>
                    <HStack gap={'16px'}>

                        {!user &&
                            <>
                                <Text {...nav_style}>
                                    Upload Comic
                                </Text>
                                <Button onClick={() => navigate('/login')} px={'24px'} colorScheme={'gray'} color={'green.400'}>Log In</Button>
                                <Button onClick={() => navigate('/register')} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>Sign Up</Button>
                            </>
                        }

                        {user &&
                            <>
                                <HStack>
                                    <Text fontSize={'14px'}>Mature content</Text>
                                    <Switch colorScheme={'green'} size='md' />
                                </HStack>
                                <SelectUploadType>
                                    <Button px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>Upload Comic</Button>
                                </SelectUploadType>
                                <Box pos={'relative'}>
                                    <IconButton onClick={() => navigate(`/account/cart`)} aria-label={'Cart'} icon={<FiShoppingCart color={'white'} />} colorScheme={'gray'} color={'green.400'} />
                                    {cart_contents.length > 0 &&
                                        <Center bottom={'4px'} right={'4px'} pos={'absolute'} borderRadius={'8px'} bg={'red'} w={'16px'} h={'16px'}>
                                            <Text fontSize={'10px'}>{cart_contents.length}</Text>
                                        </Center>
                                    }
                                </Box>
                                <Menu>
                                    <MenuButton as={Box} h={'42px'} w={'42px'} borderRadius={'8px'} bg={'#262333'} cursor={'pointer'} borderWidth={'1px'} borderColor={'#2F2C3B'}>
                                        <HStack justify={'center'} w={'full'} h={'full'}>
                                            { has_unread_notification &&
                                                <Box pos={'relative'}>
                                                    <BsFillBellFill size={'20px'} color={'#48BB78'} />
                                                    <Circle pos={'absolute'} top={'10px'} left={'10px'} size={'10px'} bg={'red.500'} />
                                                </Box>
                                                ||
                                                <FiBell size={'20px'} color={'white'} />
                                            }
                                        </HStack>
                                    </MenuButton>
                                    <MenuList pb={'16px'} w={'300px'} bg={'#1F1C2C'}>
                                        { notifications.map((item, index)=>
                                            <MenuItem key={index} bg={'#1F1C2C'} fontSize={'14px'}>
                                                <HStack align={'start'} spacing={'12px'}>
                                                    <Image borderRadius={'8px'} h={'36px'} src={'/assets/images/logo-notext.png'} />
                                                    <VStack align={'start'} spacing={0} w={'full'}>
                                                        <Text fontSize={'14px'}>{ item.title }</Text>
                                                        <Text fontSize={'small'} color={'gray.400'}>{ item.message }</Text>
                                                        <HStack justify={'end'} w={'full'}>
                                                            <Text fontSize={'10px'} color={'gray.100'}>
                                                                { moment(new Date(item.created_at)).fromNow() }
                                                            </Text>
                                                        </HStack>
                                                    </VStack>
                                                </HStack>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </Menu>
                                <Menu>
                                    <MenuButton as={Box} h={'42px'} w={'80px'} borderRadius={'8px'} bg={'#262333'} cursor={'pointer'} borderWidth={'1px'} borderColor={'#2F2C3B'}>
                                        <HStack w={'full'} h={'full'} justify={'space-between'} align={'center'} p={'4px'} pl={'6px'}>
                                            {user.profile_picture_url &&
                                                <Image borderRadius={'8px'} h={'full'} src={user.profile_picture_url} />
                                                ||
                                                <Center borderRadius={'8px'} borderWidth={'2px'} borderColor={'white'} h={'full'} w={'36px'}>
                                                    <RiUserLine size={'20px'} color={'white'} />
                                                </Center>
                                            }
                                            <Center w={'24px'}>
                                                <FiChevronDown color={'white'} />
                                            </Center>
                                        </HStack>
                                    </MenuButton>
                                    <MenuList w={'284px'} bg={'#1F1C2C'}>
                                        <HStack px={'12px'} py={'6px'} spacing={'12px'}>
                                            {user.profile_picture_url ?
                                                <Image
                                                    borderRadius={'4px'}
                                                    boxSize='43px'
                                                    objectFit='cover'
                                                    src={user.profile_picture_url}
                                                    alt={'Profile Picture'}
                                                />
                                                :
                                                <Center borderRadius={'8px'} borderWidth={'2px'} borderColor={'white'} h={'40px'} w={'42px'}>
                                                    <RiUserLine size={'20px'} color={'white'} />
                                                </Center>
                                            }

                                            <VStack align={'start'} spacing={0}>
                                                <Text fontSize={'14px'}>{user.firstname} {user.lastname}</Text>
                                                <Text fontSize={'small'} color={'gray.400'}>{user.email}</Text>
                                            </VStack>
                                        </HStack>
                                        <MenuDivider />
                                        <MenuItem onClick={() => navigate('/account/settings')} bg={'#1F1C2C'} fontSize={'14px'}>Account Settings</MenuItem>
                                        <MenuItem onClick={() => navigate('/account/contents')} bg={'#1F1C2C'} fontSize={'14px'}>My Uploads</MenuItem>
                                        <MenuItem bg={'#1F1C2C'} fontSize={'14px'}>My Library</MenuItem>
                                        <MenuItem onClick={() => navigate('/account/wallet')} bg={'#1F1C2C'} fontSize={'14px'}>My Wallet</MenuItem>
                                        <MenuItem bg={'#1F1C2C'} fontSize={'14px'}>Creator Dashboard</MenuItem>
                                        <MenuItem bg={'#1F1C2C'} fontSize={'14px'}>Help Center</MenuItem>
                                        <MenuItem bg={'#1F1C2C'} fontSize={'14px'} onClick={logout}>Logout</MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        }
                    </HStack>
                </HStack>
                ||
                <MobileHeader activeNav={active_nav} />
            }
        </>
    )
}